import React from "react"
import { Link } from "gatsby"

import "../assets/style.scss"
import learningRight from "../images/learningRight.svg"
import ReactMarkdown from "react-markdown"

export default function Learning(props) {
  return (
    <div>
      <img
        src={learningRight}
        alt="scholarshipRight"
        className="projectsRight position-absolute"
      ></img>
      <div className="container projects-text">
        <br />
        <br />
        <div className="row">
          <div className="col-lg-7">
            <h1>{props.data.title}</h1>
            <br />
            <br />
            <ReactMarkdown>{props.data.description}</ReactMarkdown>
            <br />
            <Link to="/donate" className="btn btn-primary">
              Donate school supplies
            </Link>
          </div>
          <div className="col-md-12 mt-4 px-0 mx-0">
            <div
              className="d-flex mt-4 justify-content-start"
              style={{ overflowX: "auto" }}
            >
              <div className="m-2 pl-2">
                <div className="circle-1 heading text-center">
                  <h6 className="mt-4 pt-3">Digital Classroom</h6>
                </div>
              </div>
              <div className="m-2">
                <div className="circle-2 heading text-center">
                  <h6 className="mt-4 pt-3">Teachers’ Training</h6>
                </div>
              </div>
              <div className="m-2 pr-4">
                <div className="circle-3 heading text-center">
                  <h6 className="mt-4 pt-3">
                    School <br /> Library
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
