import React from "react"
import { Link } from "gatsby"
import "../assets/style.scss"
import eggucationRight from "../images/eggucationRight.webp"
//import eggucationRightLow from "../images/eggucationRight (1).webp"
import ReactMarkdown from "react-markdown"
//import LazyImage from "./LazyImage"

export default function Eggucation(props) {
  return (
    <div>
      <img
        src={eggucationRight}
        alt="eggucationRight"
        className="projectsRight position-absolute"
      ></img>
      {/* <LazyImage
        className="projectsRight position-absolute"
        lowRes={eggucationRightLow}
        highRes={eggucationRight}
        style={{ marginTop: "20rem" }}
        width="100%"
        alt="First slide"
      /> */}
      <div className="container projects-text">
        <br />
        <br />
        <div className="row">
          <div className="col-lg-7">
            <h1>{props.data.title}</h1>
            <br />
            <br />
            <ReactMarkdown>{props.data.description}</ReactMarkdown>
            <br />
            <Link to="/donate" className="btn btn-primary">
              Donate for Eggucation
            </Link>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-12 mt-4 px-0 mx-0">
            <div
              className="d-flex mt-4 justify-content-start"
              style={{ overflowX: "auto" }}
            >
              <div className="m-2 pl-2">
                <div className="circle-1 heading text-center">
                  <h3 className="mt-4 pt-1">78</h3>
                  <div style={{ fontSize: "0.75rem" }}>KCal/Day</div>
                </div>
              </div>
              <div className="m-2 pr-4">
                <div className="circle-2 heading text-center">
                  <h3 className="mt-4">$25</h3>
                  <div style={{ fontSize: "0.75rem" }}>
                    For entire school’s meal/day
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
