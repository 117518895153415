import React from "react"

import "../assets/style.scss"
import infrastructureRight from "../images/infrastructureRight.svg"
import ReactMarkdown from "react-markdown"

export default function Infrastructure(props) {
  return (
    <div>
      {" "}
      <img
        src={infrastructureRight}
        alt="scholarshipRight"
        className="projectsRight position-absolute"
      ></img>
      <div className="container projects-text">
        <br />
        <br />
        <div className="row">
          <div className="col-lg-7">
            <h1>{props.data.title}</h1>
            <br />
            <br />
            <ReactMarkdown>{props.data.description}</ReactMarkdown>
            <br />
            <button className="btn btn-primary">
              Contribute to the fundraising
            </button>
          </div>
          <div className="col-md-12 mt-4 px-0 mx-0">
            <div
              className="d-flex mt-4 justify-content-start "
              style={{ overflowX: "auto" }}
            >
              <div className="m-2 pl-2">
                <div className="circle-1 heading text-center">
                  <h6 className="mt-4 pt-3">
                    Solar <br /> Energy
                  </h6>
                </div>
              </div>
              <div className="m-2">
                <div className="circle-2 heading text-center">
                  <h6 className="mt-4  pt-3">
                    Fresh <br />
                    Water
                  </h6>
                </div>
              </div>
              <div className="m-2 pr-4">
                <div className="circle-3 heading text-center">
                  <h6 className="mt-4  pt-3">
                    Clean <br /> Toilet
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
