import React from "react"
import { Link } from "gatsby"

import "../assets/style.scss"
import scholarshipRight from "../images/scholarshipRight.svg"
import ReactMarkdown from "react-markdown"

export default function Scholarship(props) {
  return (
    <div>
      <img
        src={scholarshipRight}
        alt="Right"
        className="projectsRight position-absolute"
        width="100%"
      ></img>
      <div className="container projects-text">
        <br />
        <br />
        <div className="row">
          <div className="col-lg-7">
            <h1>{props.data.title}</h1>
            <br />
            <br />
            <ReactMarkdown>{props.data.description}</ReactMarkdown>
            <br />
            <Link to="/donate" className="btn btn-primary">
              Donate for scholarship
            </Link>
          </div>
          <div className="col-md-12 mt-4 px-0 mx-0">
            <div
              className="d-flex mt-4 justify-content-start"
              style={{ overflowX: "auto" }}
            >
              <div className="m-2 pl-2">
                <div className="circle-1 heading text-center">
                  <h3 className="mt-4 pt-1">160</h3>
                  <div style={{ fontSize: "0.8rem" }}>Children</div>
                </div>
              </div>
              <div className="m-2">
                <div className="circle-2 heading text-center">
                  <h3 className="mt-4">$25</h3>
                  <div style={{ fontSize: "0.75rem" }}>
                    For a primary school kid/month
                  </div>
                </div>
              </div>
              <div className="m-2 pr-4">
                <div className="circle-3 heading text-center">
                  <h3 className="mt-4">$35</h3>
                  <div style={{ fontSize: "0.75rem" }}>
                    For a high school kid/month
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
