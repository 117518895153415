import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import querystring from "query-string"
import "../assets/style.scss"
import SEO from "../components/seo"
import Header from "../components/Header"
import Footer from "../components/Footer"

import schoolarshipSelected from "../images/schoolarshipSelected.svg"

import learningSelected from "../images/learningSelected.svg"

import eggucationSelected from "../images/eggucationSelected.svg"
import infrastructureSelected from "../images/infrastructureSelected.svg"

import Scholarship from "../components/Scholarship"
import Learning from "../components/Learning"
import Eggucation from "../components/Eggucation"
import Infrastructure from "../components/Infrastructure"
import AOS from "aos"
import "aos/dist/aos.css"

const getID = url => {
  const tab = querystring.parse(url).tab
  return tab
}

const ProjectsPage = ({ data, location }) => {
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    AOS.init({
      duration: 1500,
    })

    if (window.innerWidth < 1000) {
      setMobile(true)

      setStateSchoolarship(true)
      setStateLearning(true)
      setStateEggucation(true)
      setStateInfrastructure(true)
    }
  }, [])

  const tab = +getID(location.search)

  const [stateSchoolarship, setStateSchoolarship] = useState(
    tab !== 2 && tab !== 3 && tab !== 4 ? true : false
  )
  const [stateLearning, setStateLearning] = useState(tab === 2 ? true : false)
  const [stateEggucation, setStateEggucation] = useState(
    tab === 3 ? true : false
  )
  const [stateInfrastructure, setStateInfrastructure] = useState(
    tab === 4 ? true : false
  )

  const changeComponent = () => {
    setStateSchoolarship(false)
    setStateLearning(false)
    setStateEggucation(false)
    setStateInfrastructure(false)
  }
  return (
    <div style={{ width: "100%", maxWidth: "100%" }}>
      <SEO title="Projects" />
      <Header currentPage="projects" />

      {!mobile ? (
        <div>
          <div className="container text-center m-top" data-aos="fade-up">
            <h1>As of now, we are running </h1>
            <h1>
              <strong>4 primary projects</strong>
            </h1>
          </div>

          <div className="container my-5 fade-in" data-aos="fade-up">
            <div className="row text-center pt-3">
              <a
                href="#scholarship"
                onClick={() => {
                  changeComponent()
                  setStateSchoolarship(true)
                }}
                className="col-lg-3 text-decoration-none"
              >
                <img
                  src={schoolarshipSelected}
                  alt="schoolarship"
                  className={stateSchoolarship ? "" : "gray-filter"}
                ></img>

                <h5
                  className={
                    stateSchoolarship
                      ? "mt-3 font-weight-normal"
                      : "mt-3 text-secondary"
                  }
                >
                  Scholarship
                </h5>
              </a>
              <a
                href="#learning"
                onClick={() => {
                  changeComponent()
                  setStateLearning(true)
                }}
                className="col-lg-3 text-decoration-none"
              >
                <img
                  src={learningSelected}
                  alt="learningSelected"
                  className={stateLearning ? "" : "gray-filter"}
                ></img>

                <h5
                  className={
                    stateLearning
                      ? "mt-3 font-weight-normal"
                      : "mt-3 text-secondary"
                  }
                >
                  Learning
                </h5>
              </a>
              <a
                href="#eggucation"
                onClick={() => {
                  changeComponent()
                  setStateEggucation(true)
                }}
                className="col-lg-3 text-decoration-none"
              >
                <img
                  src={eggucationSelected}
                  alt="eggucationSelected"
                  className={stateEggucation ? "" : "gray-filter"}
                ></img>

                <h5
                  className={
                    stateEggucation
                      ? "mt-3 font-weight-normal"
                      : "mt-3 text-secondary"
                  }
                >
                  Eggucation
                </h5>
              </a>
              <a
                href="#infrastructure"
                onClick={() => {
                  changeComponent()
                  setStateInfrastructure(true)
                }}
                className="col-lg-3 text-decoration-none"
              >
                <img
                  src={infrastructureSelected}
                  alt="infrastructureSelected"
                  className={stateInfrastructure ? "" : "gray-filter"}
                ></img>

                <h5
                  className={
                    stateInfrastructure
                      ? "mt-3 font-weight-normal"
                      : "mt-3 text-secondary"
                  }
                >
                  Infrastructure
                </h5>
              </a>
            </div>
          </div>
        </div>
      ) : null}
      <br />
      <br />
      {mobile ? <div style={{ marginTop: "10rem" }} /> : ""}
      {stateSchoolarship ? (
        <div data-aos="fade">
          <Scholarship data={data.strapiProjectsPage.Projects[0]} />
        </div>
      ) : null}
      {mobile ? <div style={{ marginTop: "10rem" }} /> : ""}

      {stateLearning ? (
        <div data-aos="fade">
          <Learning data={data.strapiProjectsPage.Projects[1]} />
        </div>
      ) : null}
      {mobile ? <div style={{ marginTop: "10rem" }} /> : ""}

      {stateEggucation ? (
        <div data-aos="fade">
          <Eggucation data={data.strapiProjectsPage.Projects[2]} />
        </div>
      ) : null}
      {mobile ? <div style={{ marginTop: "10rem" }} /> : ""}

      {stateInfrastructure ? (
        <div data-aos="fade">
          <Infrastructure data={data.strapiProjectsPage.Projects[3]} />
        </div>
      ) : null}

      <div className="m-top"></div>
      <Footer />
    </div>
  )
}

export default ProjectsPage

export const pageQuery = graphql`
  query ProjectsQuery {
    strapiProjectsPage {
      Projects {
        description
        id
        title
      }
    }
  }
`
